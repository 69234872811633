/* Login.module.css */
.logoContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
}

.logo {
  float: right;
  width: 25%;
  height: 100%;
}

.buttonStyle {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: flex-start;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  height: 50px;
}

.buttonStyle:hover {
  cursor: pointer;
  border: 1px solid black;
}

.img {
  margin-left: -34px;
  border-radius: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.heading {
  color: #000;
  font-size: 1rem;
  line-height: 4rem;
}

.heading span {
  background-clip: text;
  background-image: linear-gradient(to right, #239ce2, #1369b9);
  color: transparent;
}
