.containerWrapper {
  width: 100%;
}

.container {
  font-family: "Montserrat";
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  display: flex;
  margin-top: 1%;
  color: #fff;
  border-radius: 12px 12px 12px 12px;
  margin-bottom: 10px;
  width: 100%;
  min-height: 140px;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 50%;
}

.main {
  margin-left: 14px;
  margin-right: 12px;
  margin-top: 15px;
  display: flex;
  gap: 30px;
}

.employeeInfo {
  max-width: 50%;
  flex: 1;
  top: 16px;
  left: 16px;
  padding: 12px 22px 12.02px 21px;
  gap: 0px;
  border-radius: 12px 12px 12px 12px;
  /* opacity: 0px; */
  background: linear-gradient(180deg, #cf4338 0%, #9a281e 100%);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 163px;
  height: 36px;
}

.headerMenu {
  display: flex;
  gap: 25px;
}

.designation {
  font-size: 18px;
}

.nameStyle {
  font-size: 25px;
  /* font-weight: bold; */
  margin-bottom: 5px;
}

.profiledetails {
  margin-top: 15px;
  font-size: 0.875rem;
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.customLink {
  color: skyblue;
  text-decoration: none;
}

.customLink:hover {
  color: black;
  text-decoration: none;
}

.iconTextWrapper span {
  margin-right: 6px;
}

.socialLink {
  display: flex;
  gap: 16px;
}

.sectionTitle {
  font-size: 1rem;
  width: 20%;
  background: #9a281e;
  padding: 5px;
  border-radius: 15px;
  color: white;
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section .content {
  display: flex;
  flex-direction: column;
}

.skillSection {
  font-size: 15px;
  gap: 5px;
  margin: 10px 0 5px 0;
}

.section .content .partionedItems {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.section .content .item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 1rem;
}

.content .subtitleHeading,
.content .customSubHeading,
.content .blury,
.content .overview,
.content .duration,
.content .points {
  margin-bottom: 0.01px;
}

.section .content .partionedItems .educationItem:not(:last-child) {
  padding: 0.3rem 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1000;
}

.section .content .educationItem:not(:last-child) {
  padding: 0.3rem 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1000;
}

.educationItem:last-child {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1000;
}

.section .content .item:last-child {
  border-bottom: none;
}

.content .title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.content .duration {
  font-size: 0.875rem;
}

.content .subtitle {
  font-size: 15.5px;
  font-weight: medium !important;
  max-width: 250px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.content .subtitleHeading {
  font-weight: bold;
  font-size: 16px;
}

.separator-line {
  border-right: 2px solid #030302;
  height: auto;
}

ul {
  margin-bottom: 0px !important
}


.content .overview {
  font-size: 0.875rem;
}

.content .link {
  display: flex;
  gap: 5px;
  font-size: 0.75rem;
  cursor: pointer;
  color: var(--color);
}

.content .date {
  display: flex;
  gap: 0 10px;
  align-items: center;
  font-size: 0.875rem;
}

.content .passingDate {
  font-size: 0.875rem;
  text-align: left;
  letter-spacing: 3;
}

.content .date svg {
  height: 14px;
  width: 14px;
}

.content .points {
  font-size: 0.875rem;
  text-align: left;
  list-style-type: disc;
}

.separate {
  border-top: 3px solid #b3b381;
}

.hidden {
  display: none;
}

.socialLink > a {
  text-decoration: none;
  color: inherit;
}

.subtitleHeading,
.subtitle {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-top: 3px;
  font-size: 20px;
}

.customHeading {
  color: #9a281e;
}

.customSubHeading {
  font-size: 15px;
}

.blury {
  opacity: 0.7;
}

@media print {
  .avoidBreak {
    page-break-inside: avoid;
  }
}

/* Responsive */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
    min-height: 180px;
  }

  .main {
    flex-direction: row;
  }

  .flexCenter {
    max-width: 50%;
  }

  .employeeInfo {
    max-width: 50%;
  }

  .section .content .partionedItems {
    grid-template-columns: repeat(2, 1fr);
  }

  .sectionTitle {
    width: 21%;
  }
}

@media (min-width: 1024px) {
  .container {
    min-height: 140px;
  }

  .section .content .partionedItems {
    grid-template-columns: repeat(2, 1fr);
  }
}
