.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.sub-section {
  width: 100%;
}

.mandetory {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.certificationTitle {
  margin-bottom: 8px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.spin {
  display: "flex";
  justify-content: center;
  align-items: "center";
}
